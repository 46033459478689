import React, { useState, useEffect } from 'react';
import * as FaIcons from 'react-icons/fa';
import * as AiIcons from 'react-icons/ai';
import { Link } from 'react-router-dom';
import { SidebarData } from './SidebarData';
import './Navbar.css';
import { IconContext } from 'react-icons';
import axios from 'axios';
import * as RiIcons from 'react-icons/ri';
import API from "../../services/config"

function Navbar() {
  const [sidebar, setSidebar] = useState(false);
  const [sidebarDataCopy, setSidebarDataCopy] = useState([])

  const showSidebar = () => setSidebar(!sidebar);

  const cargarSubtitulos = async () => {
    let response = await axios.get(
      API.baseURL + 'api/get/subtitulos/id/0'
    )

    const aux = [
      {
        title: response.data.subtitulos.primerParrafo.toUpperCase(),
        path: '/primer-torneo',
        icon: <RiIcons.RiNumber1 />,
        cName: 'nav-text'
      },
      {
        title: response.data.subtitulos.segundoParrafo.toUpperCase(),
        path: '/segundo-torneo',
        icon: <RiIcons.RiNumber2 />,
        cName: 'nav-text'
      },
    ];

    setSidebarDataCopy(aux)

  }

  useEffect(() => {
    cargarSubtitulos()
  }, [])

  return (
    <>
      <div>
        <IconContext.Provider value={{ /*color: '#F83200'*/ }}>
            <Link to='#' className='menu-bars'>
              <FaIcons.FaBars onClick={showSidebar} />
            </Link>
          <nav className={sidebar ? 'nav-menu active' : 'nav-menu'}>
            <ul className='nav-menu-items' onClick={showSidebar}>
              <li className='navbar-toggle'>
                <Link to='#' className='menu-bars'>
                  <AiIcons.AiOutlineClose />
                </Link>
              </li>
              {sidebarDataCopy.map((item, index) => {
                return (
                  <li key={index} className={item.cName} >
                    <Link to={item.path}>
                    {/*<img
                        
                        width="100%"
        
                        className="d-inline-block align-top"
                        alt="Equipo vs Equipo"
                    />*/}
                      <div>
                        <span className='icon'>{item.icon}</span> 
                        <span>{item.title}</span>
                      </div>
                      
                    </Link>
                  </li>
                );
              })}
            </ul>
          </nav>
        </IconContext.Provider>
      </div>
    </>
  );
}

export default Navbar;